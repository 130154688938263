/* General Styles */
body {
  background-color: black;
  color: white;
  font-family: 'Arial', sans-serif; /* You can choose your font */
  margin: 0; /* Reset default margin */
}

/* Button Styles */
button {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center; /* Center the text */
  display: inline-block; /* Ensure button respects text-align */
  width: auto; /* Adjust width as necessary, auto means it will adjust based on content */
  margin-top: 20px;
}

button.red {
  background-color: red;
}

/* Navigation Styles */
.header-nav {
  background-color: #333;
  padding: 10px 0;
}

.nav-tabs {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0; /* Reset default padding */
  margin: 0; /* Reset default margin */
}

.nav-tabs li {
  margin: 0 10px;
}

.nav-tabs a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-tabs a:hover {
  color: #61dafb;
}

/* Checkbox Styles */
.checkbox-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-width: 600px;
  margin: auto;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

/* Chip Styles */
.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.chip {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.chip-icon {
  color: #fff;
  background: #007bff;
  border-radius: 50%;
  padding: 0 5px;
}

.chip-selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

/* Sidebar and Main Content Layout */
.sidebar {
  width: 25%;
  height: 100vh;
  position: fixed; /* Change 'position: left' to 'position: fixed' */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure sidebar is above other content */
}

.source-content{
  width: 75%;
  float: right;
}

.main-content {
  margin-left: 25%; /* Match the sidebar width */
  width: 70%;
  height: 100vh;
  overflow: auto;
}

/* Page Container for Transitions */
.page-container {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.page-container.active {
  opacity: 1;
}

.headline-selection {
  text-align: center;
}

.headline-item {
  margin: 10px auto; /* Center the item */
  display: block; /* Make the div a block to respect text-align */
}

