.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height as needed */
  }
  
  .signup-box {
    border: 2px solid green; /* Red border */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Add padding to create some space inside the box */
    width: 300px; /* Adjust the width as needed */
    text-align: center; /* Center text inside the box */
  }
  
  /* Additional styling for the title, input fields, and button (optional) */
  .signup-box h1 {
    font-size: 24px;
    /* Add any other styles for the title here */
  }
  
  .signup-box input[type="text"],
  .signup-box input[type="email"] {
    width: 80%;
    margin-bottom: 10px;
    padding: 10px;
    /* Add any other styles for the input fields here */
  }
  
  .signup-box button {
    background-color: red; /* Red background for the button */
    color: white; /* White text color for the button */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    /* Add any other styles for the button here */
  }
  
  .signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #2b2b2b; /* Set the background color */
  }