.chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

.chip {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 16px;
  background-color: #f1f1f1;
  cursor: pointer;
  width: 150px;
  justify-content: space-between;
  text-align: center; /* Ensure text is centered */
}

.chip-selected {
  background-color: #4caf50;
  color: white;
}

.green {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.headline-chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

h1 {
  margin-top: 20px;
  text-align: center;
}

.loading-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px); /* Adjust height to center vertically, accounting for the header */
  text-align: center;
  margin-left: 250px; /* Adjust this value based on the width of your sidebar */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4caf50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px; /* Add some space between spinner and text */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
