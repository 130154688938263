.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.checkbox-container {
  display: flex;
  justify-content: space-between;
}

.checkbox-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: white;
  margin: 0; /* Reset margin to remove any default spacing */
  padding: 0; /* Reset padding to remove any default spacing */
}

body {
  font-family: 'Georgia', serif; /* Or another serif font commonly used in reports */
  color: white; /* Dark gray text for readability */
  background-color: whitesmoke;
  margin: 0; /* Reset margin to remove any default spacing */
  padding: 0; /* Reset padding to remove any default spacing */
  display: flex;
  justify-content: center; /* Center the report content horizontally */
  align-items: center; /* Center the report content vertically */
  flex-direction: column; /* Stack elements vertically */
}

/* Center all titles */
h1, h2, h3 {
  font-weight: bold;
  text-align: center; /* Center-align all titles */
}

/* Center all paragraphs */
p {
  font-size: 30px;
  text-align: center; /* Center-align all paragraphs */
}

h1 {
  font-size: 40px; /* Example size for main titles */
}

h2 {
  font-size: 20px; /* Example size for section titles */
}

.container {
  width: 80%; /* Container width, adjust as needed */
  max-width: 800px; /* Maximum width of the container */
}

.button {
  background-color: #00ff44; /* Example button color */
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; /* Space above the button */
}

.button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

button {
  background-color: green; /* Change as needed for different buttons */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.checkbox-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three columns */
  gap: 10px; /* Adjust as needed */
  max-width: 600px; /* Adjust as needed */
  margin: auto;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

