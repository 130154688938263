.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    border: 2px solid green; /* Green border */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Add padding to create some space inside the container */
    background-color: #2b2b2b; /* Background color for the container */
    text-align: center; /* Center text inside the container */
  }
  
  .landing-form {
    width: 100%;
    max-width: 400px; /* Adjust the width as needed */
  }
  
  .landing-form input[type="text"],
  .landing-form input[type="email"],
  .landing-form button {
    margin: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }
  
  .landing-link {
    margin: 20px;
    padding: 10px;
    border: 1px solid red;
    border-radius: 5px;
    text-decoration: none;
    color: red;
  }

  button.red {
    background-color: red;
    font: 'serif';
  }
