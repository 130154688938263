/* Header.css */


.sidebar {
  height: 100%;
  width: 15%; /* Set a fixed width for the sidebar */
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #3b5998; /* Sidebar background */
  overflow-x: hidden; /* Prevent horizontal scroll */
  padding-top: 20px; /* Space from the top */
  transition: 0.5s; /* Smooth transition for any hover effects */
  border-radius: 0; /* Remove rounded corners */
}


.sidebar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 15px;
  font-family: "Lucida Console", monospace;
  color: cornsilk;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1c5; /* Highlight on hover */
}

.sidebar button {
  color: white; /* Example style */
  background-color: lightslategray; /* Example style */
  border: none; /* Example style */
  /* Add more styling as per your design */
}

/* Style for the rest of the page content */
.content {
  margin-left: 100px; /* Push content to the right to make space for the sidebar */
  transition: margin-left .5s; /* Smooth transition for margin changes */
}

/* Adjustments for smaller screens or when the sidebar is not needed */
@media screen and (max-width: 600px) {
  .sidebar {
    width: 0; /* Hide the sidebar on small screens */
    padding-top: 15px;
  }
  .content {
    margin-left: 0; /* Remove margin */
  }
}

.logout-btn {
  position: absolute;
  bottom: 20px; /* Adjust distance from the bottom */
  left: 50%;
  transform: translateX(-50%);
  /* Style your button further as needed */
  color: white;
  background-color: #f44336; /* Red color for logout to indicate action */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #c53727; /* Darker shade on hover */
}

.sidebar ul, .sidebar ol {
  list-style-type: none; /* Removes bullet points or numbers */
  padding: 0; /* Removes the default padding */
  margin: 0; /* Removes the default margin */
}


